import React, { useCallback, useMemo } from "react";
import styles from "./HappeningList.module.scss";
import classNames from "classnames";
import { HappeningListItemViewModel } from "../../openapi/backend";
import HappeningListCard from "../HappeningListCard/HappeningListCard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";
import Paginate from "../Paginate/Paginate";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ReactPaginateProps } from "react-paginate";
import { hasValues, isEmpty } from "../../utils/Array";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import { setToSearch } from "../../utils/RouterUtils";
import { HAPPENING_CLUSTER_KEY, HAPPENING_DETAILS_KEY, PAGE_KEY } from "../../utils/RouterConstants";
import { useClusterId, useShowFilters } from "../../hooks/useRouterHooks";
import GoToFiltersButton from "../GoToFiltersButton/GoToFiltersButton";
import Button from "../Button/Button";

type Props = {
    className: string;
    list?: HappeningListItemViewModel[];
    loading: boolean;
    getPage: (event: { selected: number }) => void;
    pageCount: number;
    page: number;
    focusFilters: () => void;
    showBackButton?: boolean;
};

const HappeningList = ({
    className,
    list,
    loading,
    getPage,
    pageCount,
    page,
    focusFilters,
    showBackButton = true,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const location = useLocation();
    const showFilters = useShowFilters();
    const showingClusterList = useClusterId();

    const paginateDisplayOptions = useMemo<Partial<ReactPaginateProps>>(() => {
        switch (true) {
            case width <= 500:
                return { pageRangeDisplayed: 3, marginPagesDisplayed: 1 };
            case width <= 800:
                return { pageRangeDisplayed: 5, marginPagesDisplayed: 2 };
            case width <= 1200:
                return { pageRangeDisplayed: 8, marginPagesDisplayed: 2 };
            default:
                return { pageRangeDisplayed: 10, marginPagesDisplayed: 3 };
        }
    }, [width]);

    const goBack = useCallback(() => navigate(-1), [navigate]);

    return (
        <>
            <div className={classNames(styles.container, className)}>
                {showBackButton && showingClusterList && (
                    <div className={classNames(styles.listContainer, styles.navButtonContainer)}>
                        <Button
                            className={classNames(styles.back)}
                            displayType="navigation"
                            onClick={goBack}
                            label={t("navigation.back.cluster")}
                        />
                    </div>
                )}
                {hasValues(list) && (
                    <div className={styles.listContainer}>
                        {list.map((happening) => {
                            if (happening.metaData.isCluster && !showingClusterList) {
                                return (
                                    <Link
                                        key={happening.id}
                                        className={styles.item}
                                        to={{
                                            pathname: `${HAPPENING_CLUSTER_KEY}/${happening.metaData.clusterId}`,
                                            search: setToSearch([{ key: PAGE_KEY, value: "1" }], location.search),
                                        }}
                                        data-testid="HappeningListLink"
                                    >
                                        <HappeningListCard happening={happening} />
                                    </Link>
                                );
                            }

                            return (
                                <Link
                                    key={happening.id}
                                    className={styles.item}
                                    to={{
                                        pathname: location.pathname,
                                        search: setToSearch(
                                            [{ key: HAPPENING_DETAILS_KEY, value: happening.id }],
                                            location.search,
                                        ),
                                    }}
                                    data-testid="HappeningListLink"
                                >
                                    <HappeningListCard happening={happening} />
                                </Link>
                            );
                        })}
                    </div>
                )}
                {isEmpty(list) && <Text className={styles.noResults} label={t("list.empty")} type="footer" />}
                {loading && <Loading className={styles.loading} />}
                {showFilters && <GoToFiltersButton focusFilters={focusFilters} className={styles.goToFiltersButton} />}
                <Paginate
                    className={styles.paginate}
                    pageCount={pageCount}
                    onPageChange={getPage}
                    forcePage={page}
                    {...paginateDisplayOptions}
                />
            </div>
        </>
    );
};

HappeningList.defaultProps = {
    className: "",
    pageCount: 0,
    page: 0,
};

export default HappeningList;
