import React, { PropsWithChildren } from "react";
import styles from "./DetailLine.module.scss";
import classNames from "classnames";
import {
    CalendarDarkIcon,
    TargetGroupDarkIcon,
    EuroDarkIcon,
    ClockDarkIcon,
    TargetGroupGroupDarkIcon,
    AccessibilityIcon,
} from "../../assets/images/Images";

type LineType = "date" | "targetGroup" | "targetGroupGroup" | "price" | "duration" | "accessibility";

type Props = {
    className: string;
    type: LineType;
};

function getIcon(type: LineType) {
    switch (type) {
        case "date":
            return CalendarDarkIcon;
        case "targetGroup":
            return TargetGroupDarkIcon;
        case "targetGroupGroup":
            return TargetGroupGroupDarkIcon;
        case "price":
            return EuroDarkIcon;
        case "duration":
            return ClockDarkIcon;
        case "accessibility":
            return AccessibilityIcon;
    }
}

const DetailLine = ({ className, type, children }: PropsWithChildren<Props>) => {
    return (
        <div className={classNames(styles.container, className)}>
            <img className={styles.icon} src={getIcon(type)} alt={type} />
            <div className={styles.content}>{children}</div>
        </div>
    );
};

DetailLine.defaultProps = {
    className: "",
};

export default DetailLine;
