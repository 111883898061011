import React from "react";
import styles from "./Text.module.scss";
import classNames from "classnames";
import { HtmlParagraphProps } from "../../types/ReusableTypes";

type TextStyle =
    | "body"
    | "footer"
    | "bold"
    | "info-card"
    | "info-card-dark"
    | "info-details"
    | "small"
    | "small-bold"
    | "extra-small-bold"
    | "inline-link"
    | "list-item"
    | "error"
    | "label-for-input";

type Props = Omit<HtmlParagraphProps, "children"> & {
    label: string;
    type: TextStyle;
};

const Text = ({ className, label, type, ...props }: Props) => {
    return (
        <p className={classNames(styles.container, styles[type], className)} {...props}>
            {label}
        </p>
    );
};

Text.defaultProps = {
    className: "",
    type: "body",
};

export default Text;
