import i18n, { t } from "i18next";
import { Env } from "./Env";
import { HappeningRegistrationType } from "../openapi/backend";

function formatAsPrice(price: number): string {
    return new Intl.NumberFormat(i18n.language, {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(price);
}

export function getPriceLabel(price: number, minPrice?: number, maxPrice?: number): string {
    if (minPrice !== undefined && maxPrice !== undefined && minPrice === maxPrice) {
        return getPriceLabel(minPrice);
    }

    if (minPrice !== undefined && maxPrice !== undefined && minPrice !== maxPrice) {
        return `${getPriceLabel(minPrice)} - ${getPriceLabel(maxPrice)}`;
    }

    if (price === 0) {
        return t("price.free");
    }

    return formatAsPrice(price);
}

export function getPriceLabelAsIndividual(price: number): string {
    return t("price.asIndividual", { price: getPriceLabel(price) });
}

export function getPriceLabelAsGroup(price: number): string {
    return t("price.asGroup", { price: getPriceLabel(price) });
}

export function isGroupRegistration(type: HappeningRegistrationType | undefined): boolean {
    return type === HappeningRegistrationType.GroupRegistration;
}

export function getDynamicHappeningAppUrl(id: string) {
    return Env.REACT_APP_HAPPENING_DYNAMIC_APP_URL?.replace("<id>", id);
}

export function getSAHappeningRegistrationUrl(id: string) {
    return `${Env.REACT_APP_BACKEND_URL}/register/${id}`;
}
