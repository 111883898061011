/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum HappeningRegistrationType {
    NoRegistration = "NoRegistration",
    SoloRegistration = "SoloRegistration",
    GroupRegistration = "GroupRegistration",
}

export function HappeningRegistrationTypeFromJSON(json: any): HappeningRegistrationType {
    return HappeningRegistrationTypeFromJSONTyped(json, false);
}

export function HappeningRegistrationTypeFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): HappeningRegistrationType {
    return json as HappeningRegistrationType;
}

export function HappeningRegistrationTypeToJSON(value?: HappeningRegistrationType | null): any {
    return value as any;
}
