export const EMBED_KEY = "embed";
export const SHARE_KEY = "share";
export const GROUP_KEY = "groups";
export const PAGE_KEY = "pagina";
export const HAPPENING_DETAILS_KEY = "details";
export const HAPPENING_CLUSTER_KEY = "cluster";
export const SHOW_FILTERS_KEY = "filters";
export const DATE_START_KEY = "fromDate";
export const DATE_END_KEY = "toDate";
export const SEARCH_KEY = "query";
export const MIN_AGE_KEY = "minAge";
export const MAX_AGE_KEY = "maxAge";
export const HIDE_FULL_KEY = "vol";
export const ACCESSIBLE_ONLY_KEY = "aaanbod";
export const HOST_KEY = "host";
