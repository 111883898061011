import React from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { HappeningListItemViewModel } from "../../openapi/backend";
import HappeningListCard from "../HappeningListCard/HappeningListCard";
import styles from "./HappeningPreview.module.scss";
import { setToSearch } from "../../utils/RouterUtils";
import { EMBED_KEY, HAPPENING_DETAILS_KEY } from "../../utils/RouterConstants";

type Props = {
    className: string;
    happening: HappeningListItemViewModel;
};

const HappeningPreview = ({ className, happening }: Props) => {
    const location = useLocation();

    return (
        <Link
            target="_blank"
            rel="noreferrer"
            className={classNames(styles.container, className)}
            to={{
                pathname: `/activiteit/${happening.id}`,
                search: setToSearch(
                    [
                        { key: EMBED_KEY, value: "1" },
                        { key: HAPPENING_DETAILS_KEY, value: happening.id },
                    ],
                    location.search,
                ),
            }}
            data-testid="HappeningListLink"
        >
            <HappeningListCard className={styles.card} happening={happening} />
        </Link>
    );
};

HappeningPreview.defaultProps = {
    className: "",
};

export default HappeningPreview;
