/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    HappeningListItemMetaData,
    HappeningListItemMetaDataFromJSON,
    HappeningListItemMetaDataFromJSONTyped,
    HappeningListItemMetaDataToJSON,
    HappeningRegistrationType,
    HappeningRegistrationTypeFromJSON,
    HappeningRegistrationTypeFromJSONTyped,
    HappeningRegistrationTypeToJSON,
    HappeningState,
    HappeningStateFromJSON,
    HappeningStateFromJSONTyped,
    HappeningStateToJSON,
    HappeningTimeAndDateViewModel,
    HappeningTimeAndDateViewModelFromJSON,
    HappeningTimeAndDateViewModelFromJSONTyped,
    HappeningTimeAndDateViewModelToJSON,
    HappeningType,
    HappeningTypeFromJSON,
    HappeningTypeFromJSONTyped,
    HappeningTypeToJSON,
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
    LocationType,
    LocationTypeFromJSON,
    LocationTypeFromJSONTyped,
    LocationTypeToJSON,
    LocationViewModel,
    LocationViewModelFromJSON,
    LocationViewModelFromJSONTyped,
    LocationViewModelToJSON,
    OrganizerViewModel,
    OrganizerViewModelFromJSON,
    OrganizerViewModelFromJSONTyped,
    OrganizerViewModelToJSON,
    ProfileShortOutput,
    ProfileShortOutputFromJSON,
    ProfileShortOutputFromJSONTyped,
    ProfileShortOutputToJSON,
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelFromJSONTyped,
    TagViewModelToJSON,
    TargetGroupViewModel,
    TargetGroupViewModelFromJSON,
    TargetGroupViewModelFromJSONTyped,
    TargetGroupViewModelToJSON,
    UserSubset,
    UserSubsetFromJSON,
    UserSubsetFromJSONTyped,
    UserSubsetToJSON,
} from "./";

/**
 *
 * @export
 * @interface HappeningListItemViewModel
 */
export interface HappeningListItemViewModel {
    /**
     *
     * @type {number}
     * @memberof HappeningListItemViewModel
     */
    currentNumberOfRegistrations: number;
    /**
     *
     * @type {number}
     * @memberof HappeningListItemViewModel
     */
    maxNumberOfRegistrations: number;
    /**
     *
     * @type {OrganizerViewModel}
     * @memberof HappeningListItemViewModel
     */
    happeningGroup?: OrganizerViewModel;
    /**
     *
     * @type {number}
     * @memberof HappeningListItemViewModel
     */
    readonly freeSpots: number;
    /**
     *
     * @type {HappeningType}
     * @memberof HappeningListItemViewModel
     */
    type: HappeningType;
    /**
     *
     * @type {string}
     * @memberof HappeningListItemViewModel
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof HappeningListItemViewModel
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof HappeningListItemViewModel
     */
    description: string;
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemViewModel
     */
    isInternal: boolean;
    /**
     *
     * @type {number}
     * @memberof HappeningListItemViewModel
     */
    minAge: number;
    /**
     *
     * @type {number}
     * @memberof HappeningListItemViewModel
     */
    maxAge: number;
    /**
     *
     * @type {LocationType}
     * @memberof HappeningListItemViewModel
     */
    locationType: LocationType;
    /**
     *
     * @type {LocationViewModel}
     * @memberof HappeningListItemViewModel
     */
    location?: LocationViewModel;
    /**
     *
     * @type {number}
     * @memberof HappeningListItemViewModel
     */
    price: number;
    /**
     *
     * @type {number}
     * @memberof HappeningListItemViewModel
     */
    proRata: number;
    /**
     *
     * @type {ImageViewModel}
     * @memberof HappeningListItemViewModel
     */
    cover?: ImageViewModel;
    /**
     *
     * @type {ProfileShortOutput}
     * @memberof HappeningListItemViewModel
     */
    shortProjectLeader?: ProfileShortOutput;
    /**
     *
     * @type {HappeningState}
     * @memberof HappeningListItemViewModel
     */
    state: HappeningState;
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemViewModel
     */
    userIsRegistered: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemViewModel
     */
    isFavorite: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemViewModel
     */
    isAccessible: boolean;
    /**
     *
     * @type {Array<HappeningTimeAndDateViewModel>}
     * @memberof HappeningListItemViewModel
     */
    dateTimes: Array<HappeningTimeAndDateViewModel>;
    /**
     *
     * @type {HappeningListItemMetaData}
     * @memberof HappeningListItemViewModel
     */
    metaData: HappeningListItemMetaData;
    /**
     *
     * @type {Date}
     * @memberof HappeningListItemViewModel
     */
    registrationEndDate?: Date;
    /**
     *
     * @type {UserSubset}
     * @memberof HappeningListItemViewModel
     */
    targetGroup?: UserSubset;
    /**
     *
     * @type {HappeningRegistrationType}
     * @memberof HappeningListItemViewModel
     */
    happeningRegistrationType?: HappeningRegistrationType;
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemViewModel
     */
    isGroupHappening?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemViewModel
     */
    hasSpotInTeamForIndividuals: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemViewModel
     */
    userIsRegisteredAsGroup?: boolean;
    /**
     *
     * @type {number}
     * @memberof HappeningListItemViewModel
     */
    durationInMinutes: number;
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemViewModel
     */
    readonly isOnline: boolean;
    /**
     *
     * @type {number}
     * @memberof HappeningListItemViewModel
     */
    minPrice: number;
    /**
     *
     * @type {number}
     * @memberof HappeningListItemViewModel
     */
    maxPrice: number;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof HappeningListItemViewModel
     */
    readonly tags?: Array<TagViewModel>;
    /**
     *
     * @type {Array<TargetGroupViewModel>}
     * @memberof HappeningListItemViewModel
     */
    readonly targetGroups?: Array<TargetGroupViewModel>;
}

/**
 * Check if a given object implements the HappeningListItemViewModel interface.
 */
export function instanceOfHappeningListItemViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentNumberOfRegistrations" in value;
    isInstance = isInstance && "maxNumberOfRegistrations" in value;
    isInstance = isInstance && "freeSpots" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "isInternal" in value;
    isInstance = isInstance && "minAge" in value;
    isInstance = isInstance && "maxAge" in value;
    isInstance = isInstance && "locationType" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "proRata" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "userIsRegistered" in value;
    isInstance = isInstance && "isFavorite" in value;
    isInstance = isInstance && "isAccessible" in value;
    isInstance = isInstance && "dateTimes" in value;
    isInstance = isInstance && "metaData" in value;
    isInstance = isInstance && "hasSpotInTeamForIndividuals" in value;
    isInstance = isInstance && "durationInMinutes" in value;
    isInstance = isInstance && "isOnline" in value;
    isInstance = isInstance && "minPrice" in value;
    isInstance = isInstance && "maxPrice" in value;

    return isInstance;
}

export function HappeningListItemViewModelFromJSON(json: any): HappeningListItemViewModel {
    return HappeningListItemViewModelFromJSONTyped(json, false);
}

export function HappeningListItemViewModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): HappeningListItemViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        currentNumberOfRegistrations: !exists(json, "currentNumberOfRegistrations")
            ? (undefined as any)
            : json["currentNumberOfRegistrations"],
        maxNumberOfRegistrations: !exists(json, "maxNumberOfRegistrations")
            ? (undefined as any)
            : json["maxNumberOfRegistrations"],
        happeningGroup: !exists(json, "happeningGroup")
            ? (undefined as any)
            : OrganizerViewModelFromJSON(json["happeningGroup"]),
        freeSpots: !exists(json, "freeSpots") ? (undefined as any) : json["freeSpots"],
        type: !exists(json, "type") ? (undefined as any) : HappeningTypeFromJSON(json["type"]),
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        title: !exists(json, "title") ? (undefined as any) : json["title"],
        description: !exists(json, "description") ? (undefined as any) : json["description"],
        isInternal: !exists(json, "isInternal") ? (undefined as any) : json["isInternal"],
        minAge: !exists(json, "minAge") ? (undefined as any) : json["minAge"],
        maxAge: !exists(json, "maxAge") ? (undefined as any) : json["maxAge"],
        locationType: !exists(json, "locationType") ? (undefined as any) : LocationTypeFromJSON(json["locationType"]),
        location: !exists(json, "location") ? (undefined as any) : LocationViewModelFromJSON(json["location"]),
        price: !exists(json, "price") ? (undefined as any) : json["price"],
        proRata: !exists(json, "proRata") ? (undefined as any) : json["proRata"],
        cover: !exists(json, "cover") ? (undefined as any) : ImageViewModelFromJSON(json["cover"]),
        shortProjectLeader: !exists(json, "shortProjectLeader")
            ? (undefined as any)
            : ProfileShortOutputFromJSON(json["shortProjectLeader"]),
        state: !exists(json, "state") ? (undefined as any) : HappeningStateFromJSON(json["state"]),
        userIsRegistered: !exists(json, "userIsRegistered") ? (undefined as any) : json["userIsRegistered"],
        isFavorite: !exists(json, "isFavorite") ? (undefined as any) : json["isFavorite"],
        isAccessible: !exists(json, "isAccessible") ? (undefined as any) : json["isAccessible"],
        dateTimes: !exists(json, "dateTimes")
            ? (undefined as any)
            : ((json["dateTimes"] as Array<any>) ?? []).map(HappeningTimeAndDateViewModelFromJSON),
        metaData: !exists(json, "metaData") ? (undefined as any) : HappeningListItemMetaDataFromJSON(json["metaData"]),
        registrationEndDate: !exists(json, "registrationEndDate")
            ? (undefined as any)
            : new Date(json["registrationEndDate"]),
        targetGroup: !exists(json, "targetGroup") ? (undefined as any) : UserSubsetFromJSON(json["targetGroup"]),
        happeningRegistrationType: !exists(json, "happeningRegistrationType")
            ? (undefined as any)
            : HappeningRegistrationTypeFromJSON(json["happeningRegistrationType"]),
        isGroupHappening: !exists(json, "isGroupHappening") ? (undefined as any) : json["isGroupHappening"],
        hasSpotInTeamForIndividuals: !exists(json, "hasSpotInTeamForIndividuals")
            ? (undefined as any)
            : json["hasSpotInTeamForIndividuals"],
        userIsRegisteredAsGroup: !exists(json, "userIsRegisteredAsGroup")
            ? (undefined as any)
            : json["userIsRegisteredAsGroup"],
        durationInMinutes: !exists(json, "durationInMinutes") ? (undefined as any) : json["durationInMinutes"],
        isOnline: !exists(json, "isOnline") ? (undefined as any) : json["isOnline"],
        minPrice: !exists(json, "minPrice") ? (undefined as any) : json["minPrice"],
        maxPrice: !exists(json, "maxPrice") ? (undefined as any) : json["maxPrice"],
        tags: !exists(json, "tags")
            ? (undefined as any)
            : ((json["tags"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        targetGroups: !exists(json, "targetGroups")
            ? (undefined as any)
            : ((json["targetGroups"] as Array<any>) ?? []).map(TargetGroupViewModelFromJSON),
    };
}

export function HappeningListItemViewModelToJSON(value?: HappeningListItemViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
