import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styles from "./App.module.scss";
import HappeningOverviewPage from "./pages/HappeningOverviewPage/HappeningOverviewPage";
import HappeningDetailPage from "./pages/HappeningDetailPage/HappeningDetailPage";
import { useIgnoreWebpackOverlayErrors } from "./hooks/useIgnoreWebpackOverlay";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import HappeningCardPage from "./pages/HappeningCardPage/HappeningCardPage";

function App() {
    useIgnoreWebpackOverlayErrors();

    return (
        <div className={styles.app}>
            <Helmet titleTemplate="%s | SBG" defaultTitle="Welkom">
                <meta name="description" content="In samenwerking met Sportief Besteed Groep" />
            </Helmet>
            <Router>
                <Routes>
                    <Route path="/" element={<HappeningOverviewPage />} />
                    <Route path="/cluster/:clusterId" element={<HappeningOverviewPage />} />
                    <Route path="/preview/:happeningId" element={<HappeningCardPage />} />
                    <Route path="/activiteit/:happeningId" element={<HappeningDetailPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
