import React, { useMemo } from "react";
import styles from "./HappeningDetailInfoSection.module.scss";
import classNames from "classnames";
import { HappeningViewModel } from "../../openapi/backend";
import DetailLine from "../DetailLine/DetailLine";
import DateSessionList from "../DateSessionList/DateSessionList";
import Text from "../Text/Text";
import {
    getDynamicHappeningAppUrl,
    getPriceLabel,
    getPriceLabelAsGroup,
    getPriceLabelAsIndividual,
    getSAHappeningRegistrationUrl,
    isGroupRegistration,
} from "../../utils/HappeningUtils";
import AnchorButton from "../AnchorButton/AnchorButton";
import { formatRegisterDateMonthShort, formatTime, isInTheFuture, isInThePast } from "../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { useTargetGroupLabel } from "../../hooks/useTargetGroupLabel";
import Button from "../Button/Button";
import { useIsPrivateHappening, useIsStandardHappening } from "../../hooks/useIsHappeningType";
import { useAgeLabel } from "../../hooks/useAgeLabel";

type Props = {
    className: string;
    happening: HappeningViewModel;
    onIntentParticipation: () => void;
    disableButton: boolean;
    loading: boolean;
    error?: string;
};

const HappeningDetailInfoSection = ({
    className,
    happening,
    onIntentParticipation,
    disableButton,
    loading,
    error,
}: Props) => {
    const { t } = useTranslation();
    const {
        id,
        dateTimes,
        price,
        requiresRegistration,
        isFull,
        registrationStartDate,
        registrationEndDate,
        type,
        durationInMinutes,
        happeningRegistrationType,
        canOnlyRegisterAsGroup,
        priceForIndividual,
        isAccessible,
        hasSpotInTeamForIndividuals,
        isInternal,
    } = happening;

    const isPrivateHappening = useIsPrivateHappening(type);
    const isStandardHappening = useIsStandardHappening(type);
    const targetGroupLabel = useTargetGroupLabel(happening);
    const ageLabel = useAgeLabel(happening.minAge, happening.maxAge);
    const isGroupHappening = useMemo(() => isGroupRegistration(happeningRegistrationType), [happeningRegistrationType]);

    const button = useMemo(() => {
        if (isStandardHappening && registrationStartDate && isInTheFuture(registrationStartDate)) {
            return (
                <AnchorButton
                    className={styles.button}
                    displayType="hollow"
                    disabled={true}
                    label={t("registration.beforeStartDate", {
                        date: formatRegisterDateMonthShort(registrationStartDate),
                        time: formatTime(registrationStartDate),
                    })}
                />
            );
        } else if (isStandardHappening && registrationEndDate && isInThePast(registrationEndDate)) {
            return (
                <AnchorButton
                    className={styles.button}
                    displayType="hollow"
                    disabled={true}
                    label={t("registration.afterEndDate")}
                />
            );
        } else if (!requiresRegistration) {
            return (
                <Button
                    className={styles.button}
                    displayType="default"
                    label={t("registration.walk-in")}
                    onClick={onIntentParticipation}
                    loading={loading}
                    disabled={disableButton}
                />
            );
        } else if (isFull && (!isGroupHappening || (isGroupHappening && !hasSpotInTeamForIndividuals))) {
            return (
                <AnchorButton
                    className={styles.button}
                    displayType="hollow"
                    label={t("registration.full")}
                    href={getDynamicHappeningAppUrl(id)}
                />
            );
        }

        return (
            <AnchorButton
                displayType="default"
                label={t("registration.register")}
                href={getSAHappeningRegistrationUrl(id)}
            />
        );
    }, [
        id,
        requiresRegistration,
        isFull,
        registrationStartDate,
        registrationEndDate,
        t,
        onIntentParticipation,
        disableButton,
        loading,
        isStandardHappening,
        isGroupHappening,
        hasSpotInTeamForIndividuals,
    ]);

    const priceLabels = useMemo(() => {
        if (isGroupHappening && !canOnlyRegisterAsGroup && price > 0) {
            return [getPriceLabelAsGroup(price), getPriceLabelAsIndividual(priceForIndividual)];
        } else {
            return [getPriceLabel(price)];
        }
    }, [price, isGroupHappening, canOnlyRegisterAsGroup, priceForIndividual]);

    return (
        <div className={classNames(styles.container, className)}>
            <DetailLine type="date">
                {isPrivateHappening ? (
                    <Text type="info-details" label={t("sessions.toBeDetermined")} />
                ) : (
                    <DateSessionList sessions={dateTimes} />
                )}
            </DetailLine>
            <DetailLine type={isGroupHappening ? "targetGroupGroup" : "targetGroup"}>
                {isInternal ? <> </> : <Text type="info-details" label={ageLabel} />}
                {targetGroupLabel ? <Text type="info-details" label={targetGroupLabel} /> : <> </>}
            </DetailLine>
            {isPrivateHappening && (
                <DetailLine type="duration">
                    <Text type="info-details" label={t("duration.minutes", { value: durationInMinutes })} />
                </DetailLine>
            )}
            {isAccessible && (
                <DetailLine type="accessibility">
                    <Text type="info-details" label={t("accessibility.description")} />
                </DetailLine>
            )}
            <DetailLine type="price">
                {priceLabels.map((label, index) => (
                    <Text type="info-details" key={index} label={label} />
                ))}
            </DetailLine>
            {button}
            {error && <Text type="error" label={error} />}
        </div>
    );
};

HappeningDetailInfoSection.defaultProps = {
    className: "",
    disableButton: false,
    onIntentParticipation() {},
};

export default HappeningDetailInfoSection;
