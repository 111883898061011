/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum HappeningType {
    Happening = "Happening",
    PrivateHappening = "PrivateHappening",
    Cluster = "Cluster",
}

export function HappeningTypeFromJSON(json: any): HappeningType {
    return HappeningTypeFromJSONTyped(json, false);
}

export function HappeningTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HappeningType {
    return json as HappeningType;
}

export function HappeningTypeToJSON(value?: HappeningType | null): any {
    return value as any;
}
