import React, { PropsWithChildren } from "react";
import { HappeningsApiProvider } from "./HappeningsApiContext";
import { RegistrationsApiProvider } from "./RegistrationsApiContext";

const ApiProviderCollection = ({ children }: PropsWithChildren<{}>) => (
    <HappeningsApiProvider>
        <RegistrationsApiProvider>{children}</RegistrationsApiProvider>
    </HappeningsApiProvider>
);

export default ApiProviderCollection;
