import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./HappeningOverviewPage.module.scss";
import classNames from "classnames";
import HappeningListContainer from "../../components/HappeningList/HappeningListContainer";
import HappeningDetailModalContainer from "../../components/HappeningDetailModal/HappeningDetailModalContainer";
import HappeningListFilter from "../../components/HappeningListFilter/HappeningListFilterContainer";
import { useIsIframe, useModalHappeningId, useShare, useShowFilters } from "../../hooks/useRouterHooks";

type Props = {
    className: string;
};

const focusFilters = () => {
    document.getElementById("date-picker")?.focus();
};

const HappeningOverviewPage = ({ className }: Props) => {
    const showFilters = useShowFilters();
    const isIframe = useIsIframe();
    const isShare = useShare();
    const happeningId = useModalHappeningId();
    const containerRef = useRef<HTMLDivElement>(null);
    const [modalHeight, setModalHeight] = useState(0);
    const isInit = useRef(false); // Used for preventing iframe scrolling to top

    const onInit = useCallback(() => (isInit.current = true), []);
    useEffect(() => {
        if (!containerRef.current || !isIframe) {
            return;
        }

        const resizeAction = () => {
            const isDetailOpen = !!happeningId;
            const clientHeight = containerRef.current?.clientHeight || 300;
            window.parent.postMessage(
                {
                    height: Math.max(clientHeight, isDetailOpen ? modalHeight : 0),
                    details: !!happeningId,
                    isInit: isInit.current,
                },
                "*",
            );
        };

        resizeAction();

        const resizeObserver = new ResizeObserver(resizeAction);
        resizeObserver.observe(containerRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [happeningId, isIframe, modalHeight]);

    const onModalResize = useCallback((height: number) => {
        // Picked 125 because it worked on all resizings.
        setModalHeight(height + 125);
    }, []);

    return (
        <div ref={containerRef} className={classNames(styles.container, className)}>
            {showFilters && <HappeningListFilter className={styles.filters} />}
            <HappeningListContainer
                className={classNames(styles.list)}
                focusFilters={focusFilters}
                onInit={onInit}
                showBackButton={!isShare}
            />
            <HappeningDetailModalContainer fillPage={!isIframe} onResize={onModalResize} />
        </div>
    );
};

HappeningOverviewPage.defaultProps = {
    className: "",
};

export default HappeningOverviewPage;
